import { CartResponse, AddCartItemInput, CartInput } from '@/types/types';
import { datadogLogs } from '@datadog/browser-logs';
import { createStorefrontApiClient } from '@shopify/storefront-api-client';

// Initialize the Shopify Storefront API Client
const storefrontApiClient = createStorefrontApiClient({
  storeDomain: `${process.env.NEXT_PUBLIC_SHOPIFY_STORE_ID}.myshopify.com`,
  apiVersion: '2025-01',
  publicAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_TOKEN,
});

// GraphQL Fragments
const CartFragments = {
  cartFields: `
    fragment cartFields on Cart {
      id
      createdAt
      buyerIdentity {
        email
      }
      discountCodes {
        code
        applicable
      }
      cost {
        totalAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        totalDutyAmountEstimated
        checkoutChargeAmount {
          amount
          currencyCode
        }
      }
      estimatedCost {
        totalAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
      }
      lines(first: 10) {
        edges {
          node {
            id
            quantity
            merchandise {
              ... on ProductVariant {
                id
                title
                priceV2 {
                  amount
                  currencyCode
                }
                product {
                  handle
                }
              }
            }
            discountAllocations {
              discountApplication {
                value {
                  ... on MoneyV2 {
                    amount
                    currencyCode
                  }
                  ... on PricingPercentageValue {
                    percentage
                  }
                }
              }
            }
          }
        }
      }
    }
  `,

  errorFields: `
    fragment errorFields on CartUserError {
      field
      message
    }
  `,

  warningFields: `
    fragment warningFields on CartWarning {
      message
    }
  `,
};

// GraphQL Queries/Mutations
const CartQueries = {
  cartCreate: `
    ${CartFragments.cartFields}
    ${CartFragments.errorFields}
    ${CartFragments.warningFields}
    mutation cartCreate($input: CartInput!) {
      cartCreate(input: $input) {
        cart {
          ...cartFields
        }
        userErrors {
          ...errorFields
        }
        warnings {
          ...warningFields
        }
      }
    }
  `,

  cartGet: `
    ${CartFragments.cartFields}
    query cart($id: ID!) {
      cart(id: $id) {
        ...cartFields
      }
    }
  `,

  cartLinesAdd: `
    ${CartFragments.cartFields}
    ${CartFragments.errorFields}
    ${CartFragments.warningFields}
    mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
      cartLinesAdd(cartId: $cartId, lines: $lines) {
        cart {
          ...cartFields
        }
        userErrors {
          ...errorFields
        }
        warnings {
          ...warningFields
        }
      }
    }
  `,

  cartDiscountCodesUpdate: `
    ${CartFragments.cartFields}
    ${CartFragments.errorFields}
    ${CartFragments.warningFields}
    mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]!) {
      cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
        cart {
          ...cartFields
        }
        userErrors {
          ...errorFields
        }
        warnings {
          ...warningFields
        }
      }
    }
  `,

  cartLinesRemove: `
    ${CartFragments.cartFields}
    ${CartFragments.errorFields}
    ${CartFragments.warningFields}
    mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
      cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
        cart {
          ...cartFields
        }
        userErrors {
          ...errorFields
        }
        warnings {
          ...warningFields
        }
      }
    }
  `,

  cartLinesUpdate: `
    ${CartFragments.cartFields}
    ${CartFragments.errorFields}
    ${CartFragments.warningFields}
    mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
      cartLinesUpdate(cartId: $cartId, lines: $lines) {
        cart {
          ...cartFields
        }
        userErrors {
          ...errorFields
        }
        warnings {
          ...warningFields
        }
      }
    }
  `,

  cartDeliveryAddressesAdd: `
    ${CartFragments.cartFields}
    ${CartFragments.errorFields}
    ${CartFragments.warningFields}
    mutation cartDeliveryAddressesAdd($cartId: ID!, $addresses: [CartDeliveryAddressInput!]!) {
      cartDeliveryAddressesAdd(cartId: $cartId, addresses: $addresses) {
        cart {
          ...cartFields
        }
        userErrors {
          ...errorFields
        }
        warnings {
          ...warningFields
        }
      }
    }
  `,
};

// Cart Functions
export const createCheckoutCart = async (
  cartInput?: CartInput
): Promise<CartResponse | undefined> => {
  try {
    const { data } = await storefrontApiClient.request(CartQueries.cartCreate, {
      variables: {
        input: cartInput || {},
      },
    });

    return data.cartCreate?.cart;
  } catch (error: any) {
    datadogLogs.logger.error('createCheckoutCart - Shopify Storefront API', {
      message: error.message,
      response: error.response,
      code: error.code,
    });
  }
};

export const getCart = async (
  cartId: string | null
): Promise<CartResponse | undefined> => {
  try {
    if (!cartId) {
      throw new Error('Cart ID is required');
    }
    const { data } = await storefrontApiClient.request(CartQueries.cartGet, {
      variables: {
        id: cartId,
      },
    });
    return data.cart;
  } catch (error: any) {
    datadogLogs.logger.error('getCart - Shopify Storefront API', {
      message: error.message,
      response: error.response,
      code: error.code,
    });
  }
};

export const addCartItem = async (
  cartInput?: AddCartItemInput
): Promise<CartResponse | undefined> => {
  try {
    if (!cartInput?.cartId) {
      throw new Error('Cart ID is required');
    }
    const { data } = await storefrontApiClient.request(
      CartQueries.cartLinesAdd,
      {
        variables: {
          cartId: cartInput.cartId,
          lines: cartInput.lines || [],
        },
      }
    );

    return data.cartLinesAdd?.cart;
  } catch (error: any) {
    datadogLogs.logger.error('addCartItem - Shopify Storefront API', {
      message: error.message,
      response: error.response,
      code: error.code,
    });
  }
};

export const addDiscountCode = async (
  cartId: string,
  discountCode: string
): Promise<CartResponse | undefined> => {
  try {
    if (!cartId) {
      throw new Error('Cart ID is required');
    }
    const { data, errors } = await storefrontApiClient.request(
      CartQueries.cartDiscountCodesUpdate,
      {
        variables: {
          cartId,
          discountCodes: [discountCode],
        },
      }
    );

    return { ...data.cartDiscountCodesUpdate?.cart, userErrors: errors };
  } catch (error: any) {
    datadogLogs.logger.error('addDiscountCode - Shopify Storefront API', {
      message: error.message,
      response: error.response,
      code: error.code,
    });
  }
};

export const removeCartItem = async (
  cartId: string,
  lineIds: string[]
): Promise<CartResponse | undefined> => {
  try {
    if (!cartId) {
      throw new Error('Cart ID is required');
    }
    const { data } = await storefrontApiClient.request(
      CartQueries.cartLinesRemove,
      {
        variables: {
          cartId,
          lineIds,
        },
      }
    );

    return data.cartLinesRemove?.cart;
  } catch (error: any) {
    datadogLogs.logger.error(' removeCartItem - Shopify Storefront API', {
      message: error.message,
      response: error.response,
      code: error.code,
    });
  }
};

export const updateCartItem = async (
  cartId: string,
  lines: { id: string; quantity: number }[]
): Promise<CartResponse | undefined> => {
  try {
    if (!cartId) {
      throw new Error('Cart ID is required');
    }
    const { data } = await storefrontApiClient.request(
      CartQueries.cartLinesUpdate,
      {
        variables: {
          cartId,
          lines: lines.map((line) => ({
            id: line.id,
            quantity: line.quantity,
          })),
        },
      }
    );

    return data.cartLinesUpdate?.cart;
  } catch (error: any) {
    datadogLogs.logger.error('updateCartItem - Shopify Storefront API', {
      message: error.message,
      response: error.response,
      code: error.code,
    });
  }
};

export const addDeliveryAddress = async (
  cartId: string,
  deliveryAddress: {
    address1: string;
    address2?: string;
    city: string;
    company?: string;
    countryCode?: string;
    firstName: string;
    lastName: string;
    phone?: string;
    provinceCode?: string;
    zip: string;
  }
): Promise<CartResponse | undefined> => {
  try {
    if (!cartId) {
      throw new Error('Cart ID is required');
    }
    const { data } = await storefrontApiClient.request(
      CartQueries.cartDeliveryAddressesAdd,
      {
        variables: {
          cartId,
          addresses: [
            {
              address: {
                deliveryAddress,
              },
              oneTimeUse: true,
              selected: true,
              validationStrategy: 'DeliveryAddressValidationStrategy',
            },
          ],
        },
      }
    );

    return data.cartDeliveryAddressesAdd?.cart;
  } catch (error: any) {
    datadogLogs.logger.error('addDeliveryAddress - Shopify Storefront API', {
      message: error.message,
      response: error.response,
      code: error.code,
    });
  }
};
