'use client';
import {
  CartType,
  DeliveryFormType,
  Product,
  UserFormType,
  LineItemType,
} from '@/types/types';
import mixpanel from 'mixpanel-browser';
import { token, domain } from '@/lib/mixpanel/mixpanel.env';
import { sendGTMEvent } from '@next/third-parties/google';

mixpanel.init(token, {
  // Proxy traffic to avoid ad bl
  api_host: `${process.env.NEXT_PUBLIC_MIXPANEL_API_HOST}`,
  debug: false,
  persistence: 'cookie',
  cookie_domain: domain,
  cross_subdomain_cookie: true,
  stop_utm_persistence: false,
  record_sessions_percent: 0,
  record_block_selector: 'video',
  record_collect_fonts: true,
  record_mask_text_selector: '.masked',
});

const registerAdditionalTrackingParams = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  const searchParams = new URLSearchParams(window.location.search);
  const trackingParams = [
    'gclid',
    'fbclid',
    'ttclid',
    'dclid',
    'ko_click_id',
    'li_fat_id',
    'msclkid',
    'twclid',
    'wbraid',
  ];

  trackingParams.forEach((param) => {
    const value = searchParams.get(param);
    if (value) {
      mixpanel.register({ [param]: value });
    }
  });

  return null;
};

export const mixpanelPageView = (pageTitle?: string) => {
  registerAdditionalTrackingParams();

  let pageToTrack = {
    page_url: window.location.origin + window.location.pathname,
    page_title: pageTitle ? pageTitle : document.title,
    page_referrer: document.referrer,
  };

  mixpanel.track_pageview(pageToTrack);
};

export const mixpanelSetIdentity = (email: string | null) => {
  if (email) {
    mixpanel.identify(email.toLocaleLowerCase().trim());
  }
};

//get distinct id
export const mixpanelGetDistinctId = () => {
  return mixpanel.get_distinct_id();
};

// Define the mixpanelBtnClick function

export const mixpanelBtnClick = (
  btn: HTMLAnchorElement | HTMLButtonElement,
  cardTitle: string
) => {
  let buttonClickTrackingObject = {
    page_url: window.location.origin + window.location.pathname,
    page_title: document.title,
    card_title: cardTitle,
    //fall back to aria-label if button text is not available
    button_text: btn?.innerText
      ? btn?.innerText
      : btn?.getAttribute('aria-label'),
    //@ts-ignore
    button_url: btn.href?.replace('?hsLang=en', ''),
  };

  mixpanel.track('Button Click', buttonClickTrackingObject);
};

export const mixpanelLinkClick = (
  btn: HTMLAnchorElement,
  cardTitle: string
) => {
  let linkClickTrackingObject = {
    page_url: window.location.origin + window.location.pathname,
    page_title: document.title,
    link_label: btn?.innerText,
    card_title: cardTitle,
    link_url: btn.href,
  };

  mixpanel.track('Link Click', linkClickTrackingObject);
};

export const mixpanelPopupView = (popupTitle: string) => {
  let popupTrackingObject = {
    page_url: window.location.origin + window.location.pathname,
    page_title: document.title,
    popup_title: popupTitle,
  };

  mixpanel.track('Pop-up View', popupTrackingObject);
};

export const mixpanelFormSubmission = (
  btnText: string,
  formTitle: string,
  cardTitle: string
) => {
  registerAdditionalTrackingParams();

  let formSubmissionTrackingObject = {
    page_url: window.location.origin + window.location.pathname,
    page_title: document.title,
    button_text: btnText,
    card_title: cardTitle,
    form_title: formTitle,
  };

  mixpanel.track('Form Complete', formSubmissionTrackingObject);
  sendGTMEvent({ event: 'Form Complete', ...formSubmissionTrackingObject });
};

export const mixpanelProductImpression = ({
  product,
}: {
  product: Partial<Product>;
}) => {
  let productImpressionTrackingObject = {
    event_source: 'Online',
    currency_code: 'ZAR',
    price: product.price,
    name: product.title ?? product.sanityTitle,
    category: product.categorySlug?.replace(/-/g, ' ') ?? '',
  };

  mixpanel.track('Product Impression', productImpressionTrackingObject);
  sendGTMEvent({
    event: 'Product Impression',
    ...productImpressionTrackingObject,
  });
};

export const mixpanelProductViewDetail = ({
  product,
}: {
  product: Partial<Product>;
}) => {
  let productViewDetailTrackingObject = {
    event_source: 'Online',
    currency_code: 'ZAR',
    price: product.price,
    name: product.title ?? product.sanityTitle,
    category: product.categorySlug?.replace(/-/g, ' ') ?? '',
  };

  mixpanel.track('View Detail', productViewDetailTrackingObject);
  sendGTMEvent({ event: 'View Detail', ...productViewDetailTrackingObject });
};

export const mixpanelAddToCartEvent = (item: any) => {
  let addToCartTrackingObject = {
    id: item.product.id,
    name: item.product.sanityTitle,
    price: item.product.price,
    event_source: 'Online',
    currency_code: 'ZAR',
    category: item.product.categorySlug.replace(/-/g, ' '),
  };

  mixpanel.track('Add To Cart', addToCartTrackingObject);
  sendGTMEvent({ event: 'Add To Cart', ...addToCartTrackingObject });
};

export const mixpanelRemoveFromCartEvent = (item: any) => {
  let removeFromCartTrackingObject = {
    id: item.product.id,
    name: item.product.sanityTitle,
    price: item.product.price,
    event_source: 'Online',
    quantity: item.quantity,
    currency_code: 'ZAR',
    category: item.product.categorySlug.replace(/-/g, ' '),
  };

  mixpanel.track('Remove From Cart', removeFromCartTrackingObject);
};

export const mixpanelCheckoutEvent = (
  _personalDetails: UserFormType,
  _deliveryDetails: DeliveryFormType,
  draftOrderID: string,
  hubspotDealID: string,
  cart: CartType
) => {
  registerAdditionalTrackingParams();

  let products = cart.lineItems.map((lineItem) => {
    return {
      name: lineItem.product.sanityTitle,
      id: lineItem.product.id,
      price: lineItem.product.price,
      quantity: lineItem.quantity,
      category: lineItem.product.categorySlug.replace(/-/g, ' '),
    };
  });

  let checkoutEventTrackingObject = {
    transaction_id: draftOrderID,
    event_source: 'Online',
    cart_total: cart?.totalPrice,
    currency_code: 'ZAR',
    discount_amount: cart.discountPrice ?? '0',
    discount_code: cart.discountCode ?? '',
    products: products,
    hubspot_deal_id: hubspotDealID,
  };

  mixpanel.track('Checkout', checkoutEventTrackingObject);
  sendGTMEvent({ event: 'Checkout', ...checkoutEventTrackingObject });
};

export const mixpanelPurchaseEvent = (
  draftOrderID: string,
  hubspotDealID: string,
  lineItems: LineItemType[],
  totalPrice: number,
  isSoleProprietor: boolean
) => {
  registerAdditionalTrackingParams();

  let products = lineItems.map((lineItem) => {
    return {
      name: lineItem.product.sanityTitle,
      id: lineItem.product.id,
      price: lineItem.product.price,
      quantity: lineItem.quantity,
      category: lineItem.product.categorySlug.replace(/-/g, ' '),
    };
  });

  let purchasEventTrackingObject = {
    transaction_id: draftOrderID,
    hubspot_deal_id: hubspotDealID,
    event_source: 'Online',
    cart_total: totalPrice ?? 0,
    currency_code: 'ZAR',
    products: products,
    isSoleProprietor: isSoleProprietor,
  };

  mixpanel.track('Purchase', purchasEventTrackingObject);
  sendGTMEvent({
    event: 'Purchase',
    ...purchasEventTrackingObject,
  });
};

export const mixpanelUserProperties = (
  personalInformation: UserFormType,
  deliveryInformation: DeliveryFormType
) => {
  mixpanelSetIdentity(personalInformation.email);
  mixpanel.people.set({
    $email: personalInformation.email,
    $first_name: personalInformation.fullName,
    $phone: personalInformation.cellNumber?.replace('0', '+27'),
    $city: deliveryInformation.deliveryCity,
    $region: deliveryInformation.deliveryProvince,
  });
};

export const mixpanelRadioClick = (radioTitle: string, radioValue: string) => {
  let radioClickTrackingObject = {
    page_url: window.location.origin + window.location.pathname,
    page_title: document.title,
    radio_title: radioTitle,
    radio_value: radioValue,
  };

  mixpanel.track('Radio Click', radioClickTrackingObject);
};

export const mixpanelExperimentStarted = (
  experimentName: string,
  variantName: string
) => {
  mixpanel.track('$experiment_started', {
    'Experiment name': experimentName,
    'Variant name': variantName,
  });
};
